import React from "react";

// @ts-ignore
import {
  useNavigate,
  backendBaseURL,
  Button,
  Card,
  Divider,
  InputText,
  Toast,
  saveToLocalStorage,
  useUserLoggedIn,
  setUserLoggedInState,
  setAccessTokenState,
  LOCAL_STORAGE_ITEMS,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { login } from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";
import useStyles from "./PDLogin.style";

function PDLogin() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const toast = React.useRef();

  const classes = useStyles();

  const userLoggedIn = useUserLoggedIn();

  React.useEffect(() => {
    if (userLoggedIn) {
      setTimeout(() => {
        navigate(ROUTES.HOME);
      }, 2000);
    }
  }, [userLoggedIn]);

  if (userLoggedIn) {
    return <div className={classes.root}>{t("login.already_logged_in")}</div>;
  }

  async function loginClick(e) {
    e.preventDefault(); 
    try {
      const response = await login({ username, password });

      saveToLocalStorage(LOCAL_STORAGE_ITEMS.TOKEN, response.data.token);
      setUserLoggedInState(true);
      setAccessTokenState(response.data.token.accessToken);
      // @ts-ignore
      toast.current.show({
        severity: "success",
        summary: t("toasts.summaries.login_success"),
        detail: t("toasts.details.redirect_home"),
      });

      setTimeout(() => {
        navigate(ROUTES.HOME);
      }, 2000);

      return true;
    } catch (err) {
      console.log("Login Failed", err);
      // @ts-ignore
      toast.current.show({
        severity: "error",
        summary: t("toasts.summaries.login_failed"),
        detail: `${err.message} - ${backendBaseURL}`,
      });
    }
  }

  return (
    <>
      <Card className="flex h-full w-full justify-content-center align-items-center">
        <Card className="flex flex-column justify-content-center align-items-center">
          <form onSubmit={loginClick}>
            <div className={classes.header}>
              <div className="p-float-label">
                <InputText
                  id="userName324"
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
                <label htmlFor="userName324">{t("login.username")}</label>
              </div>
              <div className="p-float-label mt-5">
                <InputText
                  id="password652"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <label htmlFor="password652">{t("login.password")}</label>
              </div>
            </div>
            <Divider />
            <div className={classes.footer}>
              <Button
                label={t("buttons.login")}
                icon="pi pi-sign-in"
                // onClick={loginClick}
                iconPos="right"
                type="submit"
              />
            </div>
          </form>
        </Card>
      </Card>
      <Toast ref={toast} position="bottom-center" />
    </>
  );
}

export default PDLogin;
