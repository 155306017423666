import React from "react";

import {
  useNavigate,
  Button,
  Divider,
  InputText,
  Toast,
  ROUTES,
  useUserLoggedIn,
  Card,
  // @ts-ignore
} from "@projectdiction/common-react";
import {
  register,
  // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";
import useStyles from "./PDSignup.style";

function PDSignup() {
  const classes = useStyles();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const navigate = useNavigate();

  const toast = React.useRef();

  const { t } = useTranslation();

  const userLoggedIn = useUserLoggedIn();

  React.useEffect(() => {
    if (userLoggedIn) {
      setTimeout(() => {
        navigate(ROUTES.HOME);
      }, 2000);
    }
  }, [userLoggedIn]);

  if (userLoggedIn) {
    return <div className={classes.root}>{t("login.already_logged_in")}</div>;
  }

  async function signupClick() {
    if (password !== password2) {
      return;
    }
    try {
      const response = await register({
        name: `${firstName} ${lastName}`,
        username,
        password,
        email,
      });

      console.log(response.data);
      // @ts-ignore
      toast.current.show({
        severity: "success",
        summary: t("toasts.summaries.user_created"),
      });
      navigate(ROUTES.LOGIN);
    } catch (err) {
      // @ts-ignore
      toast.current.show({
        severity: "error",
        summary: t("toasts.summaries.error_occured"),
      });
      console.log("Login Failed", err);
    }
  }

  return (
    <Card
      pt={{
        root: { className: "h-full w-full" },
        body: {
          className:
            "h-full w-full flex justify-content-center align-items-center",
        },
        content: {
          className:
            "h-8 w-4 flex justify-content-center align-items-center flex-column row-gap-4",
        },
      }}
    >
      <div className="p-float-label">
        <InputText
          id="firstName324"
          type="text"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <label htmlFor="firstName324">{t("login.firstname")}</label>
      </div>
      <div className="p-float-label">
        <InputText
          id="lastName652"
          type="text"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        <label htmlFor="lastName652">{t("login.lastname")}</label>
      </div>
      <div className="p-float-label">
        <InputText
          id="email324"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <label htmlFor="email324">{t("login.email")}</label>
      </div>
      <div className="p-float-label">
        <InputText
          id="userName324"
          type="text"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <label htmlFor="userName324">{t("login.username")}</label>
      </div>
      <div className="p-float-label">
        <InputText
          id="password652"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <label htmlFor="password652">{t("login.password")}</label>
      </div>
      <div className="p-float-label">
        <InputText
          id="cpassword652"
          type="password"
          value={password2}
          onChange={(e) => {
            setPassword2(e.target.value);
          }}
        />
        <label htmlFor="cpassword652">{t("login.confirm_password")}</label>
      </div>
      <Divider />
      <div className="">
        <Button
          label="Sign up"
          icon="pi pi-user-plus"
          onClick={signupClick}
          iconPos="right"
        />
      </div>
      <Toast ref={toast} />
    </Card>
  );
}

export default PDSignup;
