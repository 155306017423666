import {
  createUseStyles,
  STYLES_FLEX_CENTER_CENTER,
  // @ts-ignore
} from "@projectdiction/common-react";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-around",
    "align-content": "space-around",
    width: "30rem",
    height: "30rem",
  },
  footer: {
    ...STYLES_FLEX_CENTER_CENTER,
  },
});

export default useStyles;
