// @ts-ignore
import { I18nextProvider } from "react-i18next";
// @ts-ignore
import i18n from "@projectdiction/translations";

import {
  Card,
  ROUTES,
  BrowserRouter,
  Routes,
  Route,
  createUseStyles,
  STYLES_CARD,
  STYLES_FLEX_CENTER_CENTER,
  // @ts-ignore
} from "@projectdiction/common-react";
import PDLogin from "./components/PDLogin";
import PDSignup from "./components/PDSignup";

const useStyles = createUseStyles({
  root: {
    ...STYLES_FLEX_CENTER_CENTER,
    marginTop: "5em",
    width: "100%",
  },
  card: {
    ...STYLES_CARD,
  },
});

export default function Root() {
  const classes = useStyles();

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.LOGIN} element={<PDLogin />} />
          <Route path={ROUTES.SIGNUP} element={<PDSignup />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
}
