import {
  createUseStyles,
  STYLES_FLEX_CENTER_CENTER,
  // @ts-ignore
} from "@projectdiction/common-react";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "space-evenly",
    width: "30rem",
    height: "30rem",
  },
  header: {
    ...STYLES_FLEX_CENTER_CENTER,
    "flex-direction": "column",
  },
  footer: {
    ...STYLES_FLEX_CENTER_CENTER,
  },
});

export default useStyles;
